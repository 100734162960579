
.kr-embedded .kr-payment-button {
    background-color: #2AD2C9 !important;	
} 

.kr-header-logo {
    content: url("../img/logo-trebol.png") !important;	
}

.row-datos_form {
    display: flex;
    justify-content: space-between;
}

.label-datos-title-muestra-form {
    font-size: 17px;
    font-weight: bold;
}

.label-datos-muestra-form {
    font-size: 17px;
}