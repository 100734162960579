@import '../../../assets/sass/variables';

.actions {
  align-items: center;
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  margin: 0 !important;

  .item {
    margin: 0 $module-rem;
    cursor: pointer;

    .icon {
      &.log-out {
        margin: 0 !important;
        margin-left: auto !important;
        opacity: 0.2;
        color: #000 !important;
      }
    }
    .tc-badge {
      justify-content: center;
      font-size: 0.8em;
      min-width: 18px;
      height: 18px;
      padding-left: 2px;
      padding-right: 2px;
      position: absolute;
      right: -5px;
      text-align: center;
      top: -5px;
    }
    &:hover {
      .tc-icon {
        opacity: 1;
      }
    }
    &.active {
      .tc-icon {
        color: accent-palette(500);
        opacity: 1;
      }
    }
  }
}

.item-link {
  transition: color 0.2s $animation;

  &:hover {
    color: main-palette(500);
  }
}

.notification-icon {
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
  
  &.active,
  &:hover {
    color: accent-palette(500);
    opacity: 0.8;
  }
}

.dropdown-link-wrap {
  position: relative;
}

.dropdown-title {
  font-size: 1.1em;
  margin: 0;
  padding: $module-rem ($module-rem * 2);
}

.action-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.action-badge {
  .ant-badge-count {
    background: $error-color;
  }
}
.action-item {
  > a {
    align-items: center;
  }

  .icon {
    border-radius: 50%;
    background: #e8e8e8;
    color: inherit;
    font-size: 1rem;
    padding: $module-rem;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    width: auto;
  }

  .text {
    display: flex;
    flex-direction: column;
    color: inherit;
    font-size: 0.9em;
    transition: color 0.2s ease-in-out;

    .sub-text {
      font-size: 0.8em;
      margin-top: $module-rem / 4;
      line-height: 1;
      opacity: 0.6;
    }
  }

  &:hover {
    .text {
      color: accent-palette(500);
    }

    .icon {
      background-color: accent-palette(500);
      color: accent-contrast(500);
    }
  }
}

.dropdown-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $module-rem ($module-rem * 2);

  .dropdown-title {
    font-size: 1.25rem;
  }

  > * {
    padding: 0;
  }
}

.dropdown-actions {
  padding: $module-rem ($module-rem * 2);
  width: 100%;
}

.ant-badge-count {
  box-shadow: 0 0 0 2px #fff !important;
  height: 18px;
  min-width: 18px;
}

.empty-item {
  display: block;
  padding: $module-rem ($module-rem * 2);
}
